<template>
	<div>
		<a-modal title="联系客服" :width="540" :visible="visible"  @cancel="handleCancel" :footer="null">
			<div>
				<a-spin :spinning="loading">
					<div class="flex center alcenter">
						<div class="ft14 ftw600 cl-main">联系客服：</div>
						<div class="ft14 ftw600 cl-theme">{{kefu.name}}</div>
					</div>
					
					<div class="flex center mt12">
						<img  v-if="kefu.qrcode != null" :src="kefu.qrcode" style="width: 100px;height: 100px;"/>
						<img v-else src="../../../../assets/image/kefu.png" style="width: 100px;height: 100px;"/>
					</div>
					
					<div class="mt8">
						<div class="text-center ft12 ftw400 cl-info">微信扫码，立即咨询</div>
					</div>
					
					<div class="mt16">
						<div class="flex center alcenter">
							<div class="ft14 ftw500 cl-info">手机号：</div>
							<div class="ft14 ftw600 cl-theme">{{kefu.mobile}}</div>
						</div>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data() {
			return {
				loading: false,
				kefu:{
					name:'',
					qrcode:null,
					mobile:'',
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/terminal/showKefu').then(res=>{
					this.kefu=res.kefu
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
		}
	}
</script>

<style>
	
</style>
