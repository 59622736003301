<template>
	<div>
		<a-modal title="直连商户支付" :width="640" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<a-form-model
					   ref="ruleForm"
						:model="form"
						:label-col="{ span: 5 }" 
						:wrapper-col="{ span: 19}">	
						<a-form-model-item label="商户ID" help="微信支付商户后台的商户ID号">
							 <a-input style="width: 300px;" v-model="form.mach_id"  placeholder="请输入微信支付商户ID" />
						</a-form-model-item>
						<a-form-model-item label="API秘钥" help="微信支付商户后台的32位的商户秘钥,V3的API秘钥请设置同样的">
							 <a-input style="width: 300px;" v-model="form.api_auth"  placeholder="请输入微信支付API秘钥" />
						</a-form-model-item>
						<a-form-model-item   label="支付证书cert" help="微信支付证书的apiclient_cert.pem文件里面的内容">
							<a-textarea style="width: 500px;" v-model="form.api_cert" placeholder="请粘贴apiclient_cert.pem文件内容" :maxLength="50000"  :rows="8" />
						</a-form-model-item>
						<a-form-model-item  label="支付证书key" help="微信支付证书的apiclient_key.pem文件里面的内容">
							<a-textarea style="width: 500px;" v-model="form.api_key" placeholder="请粘贴apiclient_key.pem文件内容" :maxLength="50000"  :rows="8" />
						</a-form-model-item>
						
					</a-form-model>	
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 20
				},
				form:{
					mach_id:'',
					api_auth:'',
					api_cert:'',
					api_key:'',
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/terminal/showShopPayment',{
				}).then(res=>{
					if(res.detail){
						this.form=res.detail;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.papi('platform/terminal/saveShopPayment',{
					mach_id:this.form.mach_id,
					api_auth:this.form.api_auth,
					api_cert:this.form.api_cert,
					api_key:this.form.api_key,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading = false;
						this.$emit('ok');
					});
				}).catch(res=>{
					this.confirmLoading = false;
					
				});
			},
			
		}
	}
</script>

<style>
	
</style>
